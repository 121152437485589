import { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface TextProps {
  size?:
    | 'normal'
    | 'larger'
    | 'large'
    | 'paragraph'
    | 'paragraphSmall'
    | 'subtitle1'
    | 'subtitle2'
    | undefined;
  className?: string;
  children?: ReactNode;
  [key: string]: any;
}

const Text: FC<TextProps> = ({
  size = 'normal',
  className,
  children,
  ...props
}) => {
  const classNames: any = {
    normal: 'font-normal text-sm',
    larger: 'font-normal text-xl',
    large: '',
    paragraph: 'font-normal text-sm md:text-base',
    paragraphSmall: 'font-normal text-xs text-[10px]',
    subtitle1: 'font-normal text-xl',
    subtitle2: 'font-normal text-lg',
  };

  return (
    <div className={twMerge(classNames[size], className)} {...props}>
      {children}
    </div>
  );
};

export default Text;
