import React, {
  ButtonHTMLAttributes,
  FC,
  HTMLAttributes,
  ReactNode,
  useMemo,
} from 'react';
import { MdClose } from 'react-icons/md';

interface ModalButtonProps<T> extends ButtonHTMLAttributes<T> {
  variant?: 'default' | 'primary' | 'secondary' | 'danger' | 'warning';
  'data-hs-overlay'?: string;
}

interface ModalProps<T> extends HTMLAttributes<T> {
  variant?: 'default' | 'primary' | 'secondary' | 'danger' | 'warning';
  size?: 'full' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  className?: string;
  overlayClass?: string;
  overlayColor?: string;
}

interface ComponentProps<T> extends HTMLAttributes<T> {
  'data-hs-overlay'?: string;
  className?: string;
  children?: ReactNode | string;
  hiddenClose?: 'false' | 'true';
  closeButton?: ReactNode | string;
  closeButtonClass?: string;
}

export const ModalButton: FC<ModalButtonProps<HTMLButtonElement>> = ({
  variant = 'default',
  className,
  ...props
}) => {
  const buttonVariant = useMemo(() => {
    let variants = variant || 'default';
    switch (variants) {
      case 'default':
        return `py-2 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-gray-600 hover:text-gray-400 disabled:opacity-50 disabled:pointer-events-none${
          className ? ' ' + className : ''
        }`;
      case 'primary':
        return `py-2 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-primary-600 text-white hover:bg-primary-700 disabled:opacity-50 disabled:pointer-events-none${
          className ? ' ' + className : ''
        }`;
      case 'secondary':
        return `py-2 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-secondary-600 text-white hover:bg-secondary-700 disabled:opacity-50 disabled:pointer-events-none${
          className ? ' ' + className : ''
        }`;
      case 'danger':
        return `py-2 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-danger-600 text-white hover:bg-danger-700 disabled:opacity-50 disabled:pointer-events-none${
          className ? ' ' + className : ''
        }`;
      case 'warning':
        return `py-2 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-warning-600 text-gray-600 hover:bg-warning-700 disabled:opacity-50 disabled:pointer-events-none${
          className ? ' ' + className : ''
        }`;
    }
  }, [variant, className]);

  return (
    <button {...props} type='button' className={buttonVariant}>
      {props.children}
    </button>
  );
};

export const Modals: FC<ModalProps<HTMLDivElement>> = ({
  id,
  size,
  className,
  children,
  variant,
  overlayClass,
  overlayColor,
}) => {
  const variants = useMemo(() => {
    let _var = variant || 'default';
    switch (_var) {
      case 'default':
        return 'bg-white dark:bg-gray-800 text-gray-700 dark:text-gray-100';
      case 'primary':
        return 'bg-primary-600 text-white';
      case 'secondary':
        return 'bg-secondary-500 text-white';
      case 'danger':
        return 'bg-danger-500 text-white';
      case 'warning':
        return 'bg-warning-500 text-gray-500';
    }
  }, [variant]);

  const sizes = useMemo(() => {
    let _size = size || 'sm';
    let _overlay = '';
    let _body = '';
    switch (_size) {
      case 'full':
        _overlay = `hs-overlay-open:mt-0 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-10 opacity-0 transition-all max-w-full h-[calc(100%-3.5rem)]${overlayClass ? ` ${overlayClass}` : ''}`;
        _body = `${variants} max-h-full mx-auto overflow-hidden pointer-events-auto flex flex-col dark:bg-gray-800${
          className ? ` ${className}` : ''
        }`;
        break;
      case 'sm':
        _overlay = `hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-in-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto h-[calc(100%-3.5rem)]${overlayClass ? ` ${overlayClass}` : ''}`;
        _body = `${variants} max-h-full mx-auto overflow-hidden pointer-events-auto flex flex-col border rounded-xl shadow-sm dark:bg-gray-800${
          className ? ` ${className}` : ''
        }`;
        break;
      case 'md':
        _overlay = `hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-in-out transition-all sm:max-w-2xl sm:w-full m-3 sm:mx-auto h-[calc(100%-3.5rem)]${overlayClass ? ` ${overlayClass}` : ''}`;
        _body = `${variants} max-h-full mx-auto overflow-hidden pointer-events-auto flex flex-col border rounded-xl shadow-sm dark:bg-gray-800${
          className ? ` ${className}` : ''
        }`;
        break;
      case 'lg':
        _overlay = `hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-in-out transition-all sm:max-w-3xl sm:w-full m-3 sm:mx-auto h-[calc(100%-3.5rem)]${overlayClass ? ` ${overlayClass}` : ''}`;
        _body = `${variants} max-h-full mx-auto overflow-hidden pointer-events-auto flex flex-col border rounded-xl shadow-sm dark:bg-gray-800${
          className ? ` ${className}` : ''
        }`;
        break;
      case 'xl':
        _overlay = `hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-in-out transition-all sm:max-w-4xl sm:w-full m-3 sm:mx-auto h-[calc(100%-3.5rem)]${overlayClass ? ` ${overlayClass}` : ''}`;
        _body = `${variants} max-h-full mx-auto overflow-hidden pointer-events-auto flex flex-col border rounded-xl shadow-sm dark:bg-gray-800${
          className ? ` ${className}` : ''
        }`;
        break;
      case '2xl':
        _overlay = `hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-in-out transition-all sm:max-w-6xl sm:w-full m-3 sm:mx-auto h-[calc(100%-3.5rem)]${overlayClass ? ` ${overlayClass}` : ''}`;
        _body = `${variants} max-h-full mx-auto overflow-hidden pointer-events-auto flex flex-col border rounded-xl shadow-sm dark:bg-gray-800${
          className ? ` ${className}` : ''
        }`;
        break;
    }
    return { overlay: _overlay, body: _body, sizes: _size };
  }, [size, className, variants, overlayClass]);

  return (
    <div
      id={id}
      className={`hs-overlay fixed start-0 top-0 z-[80] hidden h-full w-full overflow-y-auto overflow-x-hidden pointer-events-none${overlayColor ? ` hs-overlay-backdrop-open:${overlayColor}` : ''}`}
    >
      <div className={sizes.overlay}>
        <div className={sizes.body}>{children}</div>
      </div>
    </div>
  );
};

export const ModalHeader: FC<ComponentProps<HTMLDivElement>> = ({
  className,
  hiddenClose,
  closeButton,
  closeButtonClass,
  children,
  ...props
}) => {
  const closeButtonClasses = useMemo(() => {
    let hidden = hiddenClose === 'true';
    let classes = `flex justify-center items-center w-7 h-7 text-sm font-semibold rounded-full border border-transparent text-inherit disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600${
      hidden ? ' hidden' : ''
    }${closeButtonClass ? ` ${closeButtonClass}` : ''}`;
    return classes;
  }, [closeButtonClass, hiddenClose]);

  return (
    <div
      className={`flex items-center justify-between border-b dark:border-gray-700${
        className ? ` ${className}` : ''
      }`}
    >
      {children}
      <button
        type='button'
        className={closeButtonClasses}
        data-hs-overlay={props['data-hs-overlay']}
      >
        <span className='sr-only'>Close</span>
        <MdClose className='h-4 w-4 flex-shrink-0 text-inherit' />
      </button>
    </div>
  );
};

export const ModalBody: FC<ComponentProps<HTMLDivElement>> = ({
  id,
  className,
  ...props
}) => {
  return <div {...props} className={`overflow-y-auto ${className}`}></div>;
};

export const ModalFooter: FC<ComponentProps<HTMLDivElement>> = ({
  id,
  className,
  hiddenClose,
  closeButton,
  closeButtonClass,
  children,
  ...props
}) => {
  const buttonClasses = useMemo(() => {
    let hidden = hiddenClose === 'true';
    let classes = `py-2 px-3 inline-flex items-center gap-x-2 text-sm rounded-lg bg-white border text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600${
      hidden ? ' hidden' : ''
    }${closeButtonClass ? ` ${closeButtonClass}` : ''}`;
    return classes;
  }, [closeButtonClass, hiddenClose]);

  return (
    <div
      className={`flex items-center justify-end gap-x-2 border-t px-4 py-3 dark:border-gray-700 ${
        className ? ` ${className}` : ''
      }`}
    >
      <button
        type='button'
        className={buttonClasses}
        data-hs-overlay={props['data-hs-overlay']}
      >
        {closeButton || 'Close'}
      </button>
      {children}
    </div>
  );
};
