import React, { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface HeadingProps {
  size?: number | string;
  weight?: string;
  text?: string;
  alternating?: boolean;
  alternateColor?: string;
  className?: string;
  children?: ReactNode;
  [key: string]: any;
}

const Heading: FC<HeadingProps> = ({
  size = 5,
  weight = 'font-bold',
  text = '',
  alternating = false,
  alternateColor = '#476E30',
  className,
  children,
  ...props
}) => {
  const Tag = `h${size}` as keyof JSX.IntrinsicElements;

  const styles: Record<number | string, string> = {
    1: `lg:text-6xl text-4xl`,
    2: `lg:text-5xl text-3xl`,
    3: `lg:text-4xl text-2xl`,
    4: `text-xl`,
    5: `lg:text-xl text-base`,
    6: `text-base`,
    custom: `text-[72px]`,
  };

  return (
    <Tag
      className={twMerge(
        `${weight} ${styles[size]} ${alternating && 'heading-alternate'}`,
        className
      )}
      {...props}
    >
      {children || text || ''}
    </Tag>
  );
};

export default Heading;
