'use client';

import { FC, useState } from 'react';
import { RiShareBoxLine } from 'react-icons/ri';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { format } from 'date-fns';

import Heading from '@/components/common/Heading';
import Text from '@/components/common/Text';
import Button from '@/components/common/Button';
import ButtonMore from '@/components/common/ButtonMore';

import { formatValue } from '@/utils/formatNumber';

export interface IRebateItemData {
  amount?: number;
  buyAfter?: string;
  buyBefore?: string;
  claimBy?: string;
  details?: string[];
  image?: string;
  infoUrl?: string;
  modelNumber?: string;
  program?: string;
  programTitle?: string;
  title?: string;
}

export interface IRebatesItem {
  item?: IRebateItemData;
  className?: string;
  [key: string]: any;
}

const dummyData: IRebateItemData = {
  amount: 12000,
  buyAfter: '2023-05-12',
  buyBefore: '2024-05-31',
  claimBy: '2024-05-31',
  details: [
    'Must be installed by participating contractor',
    'Amount specified is for customers outside Disadvantaged Communities (DAC).',
    'Higher rebate available for customers in Disadvantaged Communities (DAC).',
    'Cannot be combined with other utility rebates.',
    'Must be a residential customer of participating utility.',
    'Must include copy of “ACCA approved version 8 Manual J” Load Calculation.',
    'Please see program home for additional requirements',
    'Proof of purchase required.',
    'Total incentive not to exceed 50 percent of the project cost',
    'Total incentives will be limited to $100,000 per customer per calendar year.',
  ],
  image: '/images/product-landing-diy/thumbnail.jpg',
  infoUrl: '',
  modelNumber: 'DIY-12-HP-WM-115C25 Heat Pump',
  program: 'Residential',
  programTitle: 'ConEdison (Electric) - NY - NYS Clean Heat Program',
  title: 'MRCOOL&reg; DIY-12-HP-WM-115C25 Heat Pump',
};

export const RebatesItem: FC<IRebatesItem> = ({
  item = dummyData,
  className,
  ...props
}) => {
  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const [openMoreDetail, setOpenMoreDetail] = useState<boolean>(false);

  function toggleDetail() {
    setOpenDetail(!openDetail);
  }

  function toggleMoreDetail() {
    setOpenMoreDetail(!openMoreDetail);
  }

  function formatDate(date: string) {
    return format(date, 'LLLL d, yyyy');
  }

  return (
    <div className='divide-y px-5'>
      <div
        className='flex w-full flex-row items-start justify-start gap-7 py-5'
        {...props}
      >
        <div className='aspect-square w-4/12 overflow-hidden rounded-md bg-[#f4f4f4] md:w-2/12'>
          <img
            src={item?.image || '/images/product-landing-diy/thumbnail.jpg'}
            alt={item?.title || ''}
            title={item?.title || ''}
            className='h-full w-full object-cover'
          />
        </div>
        <div className='relative flex flex-col items-stretch justify-start gap-y-6'>
          <div className='justify-stargap-y-2 flex flex-col items-stretch'>
            <Heading size={6}>
              {item?.title || 'MRCOOL&reg; DIY-12-HP-WM-115C25 Heat Pump'}
            </Heading>
            <Text size='paragraph'>
              Model #: {item?.modelNumber || 'DIY-12-HP-WM-115C25 Heat Pump'}
            </Text>
          </div>
          <Button
            size='small'
            color='primary'
            variant='outlined'
            className='w-fit'
            onClick={toggleDetail}
          >
            <div className='flex flex-row items-center justify-center gap-1'>
              <span>{formatValue(item?.amount || 0)} in rebates</span>
              {openDetail ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </div>
          </Button>
        </div>
      </div>

      {openDetail && (
        <div className='flex w-full flex-col items-stretch justify-start gap-7 py-5 md:flex-row md:items-start'>
          <div className='w-full md:w-2/12'>
            <Text size='paragraphSmall'>Amount</Text>
            <Heading size={6}>
              {formatValue(item?.amount || 0)} / project
            </Heading>
          </div>
          <div className='flex w-full flex-col items-stretch justify-start gap-y-6'>
            <div className='grid w-6/12 grid-cols-1 gap-x-7 md:grid-cols-2'>
              <div>
                <Text size='paragraphSmall'>
                  Program - {item?.program || 'residential'}
                </Text>
                <Heading size={6}>
                  <a
                    className='text-primary transition-all hover:text-black'
                    href='#'
                    title={
                      item?.programTitle ||
                      'ConEdison (Electric) - NY - NYS Clean Heat Program'
                    }
                  >
                    {item?.programTitle ||
                      'ConEdison (Electric) - NY - NYS Clean Heat Program'}
                  </a>
                </Heading>
              </div>
              <div className='flex flex-col items-stretch justify-start text-[10px]'>
                <div className='flex w-full flex-row items-center justify-start'>
                  <p className='w-6/12'>Buy on or after:</p>
                  <p className='w-6/12 text-right'>
                    {formatDate(item?.buyAfter || '')}
                  </p>
                </div>
                <div className='flex w-full flex-row items-center justify-start'>
                  <p className='w-6/12'>Buy on or before:</p>
                  <p className='w-6/12 text-right'>
                    <strong>{formatDate(item?.buyBefore || '')}</strong>
                  </p>
                </div>
                <div className='flex w-full flex-row items-center justify-start'>
                  <p className='w-6/12'>Claim by:</p>
                  <p className='w-6/12 text-right'>
                    {formatDate(item?.claimBy || '')}
                  </p>
                </div>
              </div>
            </div>
            <div className='flex flex-col items-start justify-start gap-y-3'>
              <Button size='small'>
                <div className='flex flex-row items-center justify-center gap-x-[6px]'>
                  <span>More info</span>
                  <RiShareBoxLine />
                </div>
              </Button>
              {item?.details && (
                <div className={openMoreDetail ? 'flex' : 'hidden'}>
                  <ul className='grid w-full list-inside list-disc grid-cols-1 md:grid-cols-2'>
                    {item?.details.map((item: string, index: number) => {
                      return (
                        <li
                          key={index}
                          className={`
                            ${index === 0 && 'font-bold'}
                            text-sm
                          `}
                        >
                          {item}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
              <ButtonMore
                isOpen={openMoreDetail}
                label={openMoreDetail ? 'Less details' : 'More details'}
                onClick={toggleMoreDetail}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
