import { FC } from 'react';
import { MdClose } from 'react-icons/md';
import { BiSolidMap } from 'react-icons/bi';

import Heading from '@/components/common/Heading';
import Text from '@/components/common/Text';
import Button from '@/components/common/Button';
import { Modals, ModalBody } from '@/components/modal';

import { RebatesItem } from '@/components/rebates/index';

export const RebatesModal: FC<{
  identifier?: string;
  [key: string]: any;
}> = ({ identifier = 'hs-modal-rebates', ...props }) => {
  return (
    <Modals id={identifier} size='xl' variant='default' {...props}>
      <ModalBody>
        <div className='relative w-full px-5 md:px-[60px]'>
          <button
            type='button'
            className='absolute right-4 top-4 z-[2] flex h-8 w-8 cursor-pointer flex-row items-start justify-end transition-all hover:opacity-80'
            data-hs-overlay={`#${identifier}`}
            title='Close'
          >
            <span className='sr-only'>Close</span>
            <MdClose className='h-[18px] w-[18px] flex-shrink-0 text-inherit' />
          </button>
          <div className='w-full'>
            <div className='flex flex-col items-center justify-center gap-y-5 pb-12 pt-16 text-center text-black'>
              <Heading size={3}>Available Rebates</Heading>
              <Text size='paragraph'>
                Check with your utility to verify eligibility &amp; requirements
                for residential rebate programs.
              </Text>
            </div>
          </div>

          <div className='w-full pb-3'>
            <p className='flex flex-row items-center justify-end gap-1 text-right'>
              <a
                href='https://www.ecorebates.com/terms-of-use'
                target='_blank'
                title='EcoRebates Terms &copy;2024'
                className='text-primary transition-all hover:text-black'
              >
                EcoRebates Terms
              </a>
              <span>&copy;2024</span>
            </p>
          </div>

          <div className='relative grid w-full grid-cols-1 gap-4 border-b border-t border-b-[#e2e2e2] border-t-[#e2e2e2] py-5 md:grid-cols-2'>
            <div className='w-full'>
              <select className='block w-full rounded-lg border border-gray-200 bg-white px-4 py-3 pe-9 font-sans focus:border-blue-500 focus:ring-blue-500 disabled:pointer-events-none disabled:opacity-50'>
                <option value='' selected>
                  MRCOOL&reg; 4th Gen DIY&reg; Single Zone
                </option>
                <option value=''>Option 2</option>
                <option value=''>Option 3</option>
              </select>
            </div>
            <div className='relative grid w-full grid-cols-1 gap-2.5 sm:grid-cols-3'>
              <div className='flex w-full flex-row items-center justify-start gap-x-[6px]'>
                <BiSolidMap className='h-[18px] w-[18px] text-primary' />
                <Text size='paragraph'>Hickory, KY</Text>
              </div>
              <div className='w-full'>
                <input
                  type='text'
                  placeholder='XXXXX'
                  className='block w-full rounded-full border border-gray-200 px-4 py-2 text-sm transition-all focus:border-primary focus:ring-primary disabled:pointer-events-none disabled:opacity-50 lg:w-[120px]'
                />
              </div>
              <div className='w-full'>
                <Button size='small' className='w-full'>
                  Update
                </Button>
              </div>
            </div>
          </div>

          <div className='relative flex w-full flex-col items-stretch justify-start divide-y'>
            {Array.from({ length: 5 }).map((_item: any, index: number) => {
              return <RebatesItem key={index} />;
            })}
          </div>
        </div>
      </ModalBody>
    </Modals>
  );
};
