import { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';

interface IButtonMore {
  isOpen?: boolean;
  label?: string;
  className?: string;
  children?: ReactNode;
  [key: string]: any;
}

const ButtonMore: FC<IButtonMore> = ({
  isOpen = false,
  label = 'More details',
  className,
  children,
  ...props
}) => {
  return (
    <button
      type='button'
      title={label}
      className={twMerge(
        'flex flex-row items-center justify-center gap-[6px] text-sm text-primary transition-all hover:text-black',
        className
      )}
      {...props}
    >
      <span>{label}</span>
      <span className='flex h-3 w-3 flex-row items-center justify-center'>
        {isOpen ? (
          <IoIosArrowUp className='h-full w-full' />
        ) : (
          <IoIosArrowDown className='h-full w-full' />
        )}
      </span>
    </button>
  );
};

export default ButtonMore;
