'use client';

import { ecoRebateData } from '@/utils/specificationHelper';
import { FC, useEffect } from 'react';

export const RebaseEcoModal: FC<{ product: string; id?: string }> = ({
  product,
  id,
}) => {
  useEffect(() => {
    const script: any = document.createElement('script');
    script.innerHTML = `
    _ecr.ecorebates${id}  = ${JSON.stringify(ecoRebateData[product])}`;
    const newCss = `
    .eco-m .eco-c {
      font-family: inherit;
    }
    .ecr-modal-content {
      border-radius: 24px;
    }
  `;
    const styleTag: any = document.createElement('style');
    styleTag.type = 'text/css';
    styleTag.innerHTML = newCss;

    document.body.appendChild(styleTag);
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
      document.body.removeChild(styleTag);
    };
  }, [product]);
  return (
    <>
      <div id={`ecorebates${id}`} style={{ fontSize: 20 }}></div>
    </>
  );
};
